import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DataProtectionComponent } from '../data-protection/data-protection.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { CookiePolicyComponent } from '../cookie-policy/cookie-policy.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { GenericMessageComponent } from '../generic-message/generic-message.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {

  public confirmationLinks = {
    de: {
      imprint: 'https://vw-wedrive-spa.s3.eu-central-1.amazonaws.com/public/210523_VWFC_EURO_Live+Experience_Impressum_Deutschland.pdf',
      // tslint:disable-next-line: max-line-length
      privacy: 'https://vw-wedrive-spa.s3.eu-central-1.amazonaws.com/public/210523_VWFC_EURO_Live+Experience_Datenschutzerkl%C3%A4rung_Deutschland.pdf'
    },
    da: {
      imprint: 'https://vw-wedrive-spa.s3.eu-central-1.amazonaws.com/public/210523_VWFC_EURO_Live+Experience_Impressum_D%C3%A4nemark.pdf',
      // tslint:disable-next-line: max-line-length
      privacy: 'https://vw-wedrive-spa.s3.eu-central-1.amazonaws.com/public/210523_VWFC_EURO_Live+Experience_Datenschutzerkl%C3%A4rung_D%C3%A4nemark.pdf'
    }
  };


  @Input() alternativeStyling = false;
  @Input() type = 'normal';
  @Input() language: string = null;

  constructor(
    private popoverController: PopoverController,
    public translate: TranslateService,
    private http: HttpClient,
  ) { }

  async ngOnInit() {
    if (!this.alternativeStyling) { // hijack this to prevent triggering popup call for all the footers, only the main one.
      // if the url has a hash, directly show the footer content for it
      window.onhashchange = () => {
        this.handleHash();
      };
      this.handleHash();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('language')) {
      this.translate.use(changes.language.currentValue);
    }
  }

  async showDataProtection() {
    const popover = await this.popoverController.create({
      component: DataProtectionComponent,
      translucent: false,
      cssClass: 'big-popover'
    });

    await popover.present();
  }

  async showTermsAndConditions() {
    const popover = await this.popoverController.create({
      component: TermsAndConditionsComponent,
      translucent: false,
      cssClass: 'big-popover'
    });

    await popover.present();
  }

  async showCookiePolicy() {
    const popover = await this.popoverController.create({
      component: CookiePolicyComponent,
      translucent: false,
      cssClass: 'big-popover'
    });

    await popover.present();
  }

  async openPopup(type, lang = this.translate.currentLang) {
    try {

      const url = `/assets/texts/${type}_${lang}.html`;
      const content = await this.http.get(url, {responseType: 'text'}).toPromise();

      const popover = await this.popoverController.create({
        component: GenericMessageComponent,
        translucent: false,
        cssClass: 'big-popover',
        componentProps: { content }
      });

      await popover.present();
    } catch (error) {
      console.log(error);
    }
  }

  /*
    https://www.wedriveeurope.com/?lang=de#imprint
    https://www.wedriveeurope.com/?lang=de#privacy-statement
    https://www.wedriveeurope.com/?lang=de#terms-and-conditions
    https://www.wedriveeurope.com/?lang=de#third-party-licenses
    https://www.wedriveeurope.com/?lang=de#cookies
  */
  handleHash() {
    switch (location.hash) {
      case '#imprint':
        this.openPopup('imprint');
        break;
      case '#privacy-statement':
        this.openPopup('privacy');
        break;
      case '#terms-and-conditions':
        this.openPopup('terms');
        break;
      case '#third-party-licenses':
        this.openPopup('packages', 'en');
        break;
      case '#cookies':
        this.openPopup('cookies');
        break;
    }
  }
}


