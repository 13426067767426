import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-generic-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss'],
})
export class GenericMessageComponent implements OnInit {

  content = "";

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    const contentElement = document.querySelector('#content');
    if (contentElement) {
      contentElement.innerHTML = this.content;
    }
    if (contentElement.querySelector("#CookieDeclaration")) {
      // add (copy from index) cookie-Table
      const cookieTable: HTMLElement = document.querySelector(".cookieTable");
      if (cookieTable) {
        cookieTable.style.display = "block"; // also show it (it's hidden initially)
        contentElement.appendChild(cookieTable);
      }
    }
  }

  close() {
    this.popoverController.dismiss();
    location.hash = '';
  }

}
