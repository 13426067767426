import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {

  lotteryID = ''
  popover: PopoverController;

  constructor(
    private navParams: NavParams,
    private router: Router
  ) {
    this.lotteryID = this.navParams.get('lotteryID');
    this.popover = this.navParams.get('popover');
  }

  ngOnInit() {}

  openLottery() {
    this.router.navigate(['ticket-lottery', 'enter', this.lotteryID]);
    this.popover.dismiss();
  }

}
