import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_LANGUAGE = 'de';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs([DEFAULT_LANGUAGE, 'en', 'da', 'es', 'hu', 'it', 'nl', 'ro', 'ru']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(DEFAULT_LANGUAGE);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const urlLang = this.getParameterByName("lang");
    const userLang = urlLang ? urlLang : navigator.language.split('-')[0];
    if (translate.getLangs().includes(userLang)) {
      translate.use(userLang);
    } else {
      translate.use(translate.defaultLang)
    }

    if (window.location.hostname === 'free-chauffeur.wedriveeurope.com') {
      router.navigate(['free-chauffeur'], {
        replaceUrl: true,
        skipLocationChange: true,
      });
    }
  }


  // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
