// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.wedriveberlin.de/graphql',
  mediaUrl: 'https://api.wedriveberlin.de',
  raffleEventId: 33,

  availableLanguages: [
    {
      code: 'de',
      name: 'Deutsch'
    },
    {
      code: 'da',
      name: 'Dansk'
    },
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'es',
      name: 'Español'
    },
    {
      code: 'it',
      name: 'Italiano'
    },
    {
      code: 'hu',
      name: 'Magyar'
    },
    {
      code: 'nl',
      name: 'Nederlands'
    },
    {
      code: 'ru',
      name: 'Pусский'
    },
    {
      code: 'ro',
      name: 'Română'
    }]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
