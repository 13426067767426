import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent implements OnInit {

  constructor(
    private popoverController: PopoverController,
    public translate: TranslateService
  ) { }

  ngOnInit() {}

  close() {
    this.popoverController.dismiss();
    location.hash = '';
  }
}
