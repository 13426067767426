import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { CookieMessageComponent } from './cookie-message/cookie-message.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { InfoComponent } from '../pages/lottery/info/info.component';
import { GenericMessageComponent } from './generic-message/generic-message.component';
@NgModule({
  declarations: [
    FooterComponent,
    CookieMessageComponent,
    DataProtectionComponent,
    TermsAndConditionsComponent,
    CookiePolicyComponent,
    LanguageSwitchComponent,
    InfoComponent,
    GenericMessageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
  ],
  exports: [
    FooterComponent,
    CookieMessageComponent,
    DataProtectionComponent,
    TermsAndConditionsComponent,
    CookiePolicyComponent,
    LanguageSwitchComponent,
    GenericMessageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
