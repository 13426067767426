import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare global {
   interface Window { digitalData: any; }
}

@Injectable({
  providedIn: 'root'
})
export class DatalayerService {
  constructor(
    private translate: TranslateService
  ) {}

  setDatalayer(
    pageInfo: object = {},
    Application: object = {},
    eventInfo: object = {}
  ) {
    window.digitalData = {
      pageInfo: Object.assign(pageInfo, {
        destinationURL: location.href,
        geoRegion: 'DE',
        language: this.translate.currentLang,
        application: 'VW UEFA'
      }),
      Application,
      Event: {
        eventInfo
      }
    };
  }

  updateDatalayer(
    pageInfo: object = {},
    Application: object = {},
    eventInfo: object = {}
  ) {
    this.setDatalayer(
      Object.assign(window.digitalData.pageInfo, pageInfo),
      Object.assign(window.digitalData.Application, Application),
      Object.assign(window.digitalData.Event.eventInfo, eventInfo),
    );
  }

  setPageName(pageName) {
    this.setDatalayer({pageName});
  }
}
