import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatalayerService } from 'src/app/services/datalayer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {

  dropdownVisible = false;
  languages = environment.availableLanguages;

  constructor(
    public translate: TranslateService,
    private datalayerService: DatalayerService
  ) { }

  ngOnInit() {}

  async selectLanguage(languageCode = 'en') {
    // do language Switch
    if (this.translate.getLangs().includes(languageCode)) {
      await this.translate.use(languageCode).toPromise();
    } else {
      return;
    }

    // close dropdown
    this.toggleDropdown(0);

    // update datalayer
    this.datalayerService.updateDatalayer({language: this.translate.currentLang});
  }

  toggleDropdown(state = -1) {
    // force passed state, else toggle
    const nextState = state > -1 ? state : !this.dropdownVisible;
    this.dropdownVisible = !!nextState; // parse to boolean;
  }
}
