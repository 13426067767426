import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { CookiePolicyComponent } from '../cookie-policy/cookie-policy.component';

@Component({
  selector: 'app-cookie-message',
  templateUrl: './cookie-message.component.html',
  styleUrls: ['./cookie-message.component.scss'],
})
export class CookieMessageComponent implements OnInit {

  constructor(
    private popoverController: PopoverController) { }

  ngOnInit(

  ) { }

  close() {
    this.popoverController.dismiss();
    location.hash = '';
  }

  async showCookiePolicy() {
    const popover = await this.popoverController.create({
      component: CookiePolicyComponent,
      translucent: false,
      cssClass: 'big-popover'
    });

    await popover.present();
  }
}
